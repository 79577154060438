@tailwind base;
@tailwind components;
@tailwind utilities;

@property --border-angle-1 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0deg;
}

@property --border-angle-2 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 90deg;
}

@property --border-angle-3 {
  syntax: "<angle>";
  inherits: true;
  initial-value: 180deg;
}

